import { graphql } from "gatsby"
import React from "react"

import CoveringImageAndText from "./CoveringImageAndText"

export const fragment = graphql`
  fragment CoveringImageAndTextFragment on WpPage_Flexlayouts_FlexibleLayouts_CoveringImageAndText {
    sectionHeading
    text
    image {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 1000
            quality: 75
            placeholder: NONE
          )
        }
      }
    }
    button {
      title
      url
      target
    }    
    config {
      imagePosition
      textAlignment {
        desktop
        mobile
        tablet
      }
  }
}
`

export const ACFCoveringImageAndText = ({ sectionHeading, text, image, button, config }) => (
  <CoveringImageAndText
    sectionHeading={sectionHeading}
    text={text}
    image={image}
    button={button}
    imagePosition={config.imagePosition}
    textAlignment={config.textAlignment}
  />
)
