import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"
import { Section, TextBlock, LinkButton } from "../../UI/Common"
import { imageCls, textAreaWrapperCls, textAreaCls } from "./CoveringImageAndText.module.scss"

const CoveringImageAndText = ({ sectionHeading, text, image, button, imagePosition, textAlignment }) => {
  let responsiveClasses = `d-flex flex-column align-items-${textAlignment.mobile} align-items-md-${textAlignment.tablet} align-items-lg-${textAlignment.desktop}`
  const textWrapper = imagePosition === "left" ? textAreaWrapperCls : `${textAreaWrapperCls}`

  return (
    <Section>
      <Row>
        <Col xs={12} lg={{ span: 6, order: imagePosition === "left" ? "first" : "last" }} >
          {image && (
            <GatsbyImage
              className={imageCls}
              image={getImage(image.localFile)}
              alt={image.altText || image.localFile?.name?.replace(/-/g, " ")}
            />
          )}
        </Col>
        <Col xs={12} lg={6}>
          <div className={`${textWrapper}`}>
            <div className={`${textAreaCls}`}>
              <h2>{sectionHeading}</h2>
              <TextBlock text={text} mode="muted" />
              {button && (
                <div className={responsiveClasses}>
                  <LinkButton
                    link={button?.url}
                    title={button?.title}
                    target={button?.target}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  )
}

export default CoveringImageAndText
