// extracted by mini-css-extract-plugin
export var bgGrey100 = "CoveringImageAndText-module--bg-grey-100--5fd93";
export var bgGrey150 = "CoveringImageAndText-module--bg-grey-150--e6909";
export var bgGrey200 = "CoveringImageAndText-module--bg-grey-200--5c399";
export var bgGrey300 = "CoveringImageAndText-module--bg-grey-300--bea04";
export var bgGrey400 = "CoveringImageAndText-module--bg-grey-400--a187d";
export var bgGrey500 = "CoveringImageAndText-module--bg-grey-500--f52b8";
export var bgGrey600 = "CoveringImageAndText-module--bg-grey-600--b63e1";
export var bgGrey700 = "CoveringImageAndText-module--bg-grey-700--290d7";
export var bgGrey800 = "CoveringImageAndText-module--bg-grey-800--4f7c5";
export var bgGrey900 = "CoveringImageAndText-module--bg-grey-900--6301a";
export var imageCls = "CoveringImageAndText-module--imageCls--fd743";
export var textAreaCls = "CoveringImageAndText-module--textAreaCls--19218";
export var textAreaWrapperCls = "CoveringImageAndText-module--textAreaWrapperCls--d44ff";
export var textGrey100 = "CoveringImageAndText-module--text-grey-100--8e4e9";
export var textGrey150 = "CoveringImageAndText-module--text-grey-150--bf297";
export var textGrey200 = "CoveringImageAndText-module--text-grey-200--bac35";
export var textGrey300 = "CoveringImageAndText-module--text-grey-300--7fca4";
export var textGrey400 = "CoveringImageAndText-module--text-grey-400--753be";
export var textGrey500 = "CoveringImageAndText-module--text-grey-500--1099f";
export var textGrey600 = "CoveringImageAndText-module--text-grey-600--d2831";
export var textGrey700 = "CoveringImageAndText-module--text-grey-700--ce28b";
export var textGrey800 = "CoveringImageAndText-module--text-grey-800--7fbae";
export var textGrey900 = "CoveringImageAndText-module--text-grey-900--f95eb";